import React, { FC } from 'react';

import Label from 'components/common/Label';
import IconCustom from 'components/common/IconCustom';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { IPromoContent } from './models';
import './PromoContent.scss';

const PromoContent: FC<IPromoContent> = ({ label: { children }, text, iconName }) => (
  <div className="dt-product-promo__content">
    <div className="dt-product-promo__content-icon-holder">
      <div className="dt-product-promo__content-split-lines">
        <span className="line1" />
        <span className="line2" />
        <span className="line3" />
      </div>
      <IconCustom icon={iconName} />
    </div>
    <Label html={children} />
    <DangerouslySetInnerHtml html={text} className="dt-product-promo__text" />
  </div>
);

export default PromoContent;
