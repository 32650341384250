import React, { FC } from 'react';

import MediaInfoBox from 'components/common/MediaInfoBox/MediaInfoBox';
import Media from 'components/common/MediaInfoBox/Media';
import Info from 'components/common/MediaInfoBox/Info';

import Image from 'components/common/Image';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import getDropDownItem from 'utils/dropDownControl';
import PromoContent from './PromoContent';

import { IProductBanner } from './models';

import './ProductBanner.scss';

const ProductBanner: FC<IProductBanner> = (props) => (
  <div className="dt-product-promo">
    <MediaInfoBox sectionLandmark={props.title}>
      <Media>
        <UmbracoImage image={props.image} alt={props.imageAlt} />
      </Media>

      <Info>
        <div className="dt-product-promo__promo-image">
          <Image localImage={props.productImage} alt={props.productImageAlt} />
        </div>
        <PromoContent
          label={{ children: props.title }}
          text={props.description}
          iconName={getDropDownItem(props.actionIconName)}
        />
      </Info>
    </MediaInfoBox>
  </div>
);

export default ProductBanner;
